<template>
    <el-dialog
    v-model="dialogVisible"
    title="用户注册"
    width="800"
    >
        <el-tabs v-model="activeName" class="demo-tabs" @tabChange="tabChange">
            <el-tab-pane label="普通用户注册" name="0">
                <div class="formBox">
                    <el-form
                        size="large"
                        style="width: 450px;"
                        ref="form"
                        :model="form"
                        :rules="rules"
                        label-width="0px">
                        <el-form-item label="" prop="username">
                            <el-input v-model.trim="form.username" placeholder="注册账户" maxlength="10"/>
                        </el-form-item>
                        <el-form-item label="" prop="password"> 
                            <el-input v-model.trim="form.password" type="password" autocomplete="off" show-password  placeholder="密码" maxlength="15"/>
                        </el-form-item>
                        <el-form-item label="" prop="pwd2">
                            <el-input v-model.trim="form.pwd2" type="password" autocomplete="off" show-password placeholder="确认密码" maxlength="15"/>
                        </el-form-item>
                        <div class="line">
                            <el-divider>
                            关联信息
                            </el-divider>
                        </div>
                        <el-form-item label="" prop="email">
                            <el-input v-model="form.emial" placeholder="邮箱账号"/>
                        </el-form-item>
                        <el-form-item label="" prop="phone">
                            <el-input v-model="form.phone" placeholder="手机号码" />
                        </el-form-item>
                        <el-form-item label="">
                            <el-button style="width: 100%;" type="primary" @click="submit('form')">提交</el-button>
                        </el-form-item>
                    </el-form>
                    
                </div>
            </el-tab-pane>
            <el-tab-pane label="博物馆用户注册" name="1">
                <div class="formBox">
                    <el-form
                        size="large"
                        style="width: 450px;"
                        ref="Mform"
                        :model="Mform"
                        :rules="Mrules"
                        label-width="0px">
                        <el-form-item label="" prop="username">
                            <el-input v-model="Mform.username"  placeholder="注册账户" />
                        </el-form-item>
                        <el-form-item label="" prop="password">
                            <el-input v-model="Mform.password" type="password" autocomplete="off" show-password placeholder="密码"/>
                        </el-form-item>
                        <el-form-item label="" prop="pwd2">
                            <el-input v-model="Mform.pwd2" type="password" autocomplete="off" show-password placeholder="确认密码" />
                        </el-form-item>
                        <div class="line">
                            <el-divider>
                            关联信息
                            </el-divider>
                        </div>
                        <el-form-item label="" prop="name">
                            <el-input v-model="Mform.name" placeholder="用户姓名"/>
                        </el-form-item>
                        <el-form-item label="" prop="site">
                            <el-select
                                style="width:100%"
                                v-model="Mform.site"
                                filterable
                                remote
                                reserve-keyword
                                placeholder="所属博物馆"
                                :remote-method="remoteMethod"
                                :loading="loading"
                            >
                                <el-option
                                v-for="item in museumOption"
                                :key="item.code"
                                :label="item.name"
                                :value="item.code"
                                />
                            </el-select>
                        </el-form-item>
                        <el-form-item label="" prop="fileUrl">
                            <el-upload
                            v-model:file-list="imageList"
                            action=""
                            multiple
                            :limit="3"
                            accept="image/*"
                            :http-request="HttpRequest"
                            :on-remove="HandleRemove"
                            :before-upload='BeforeUpload'
                            list-type="picture"
                            >
                                <el-button size="large" type="primary">上传资质</el-button>
                                <template #tip>
                                <span style="margin-left: 10px;">
                                    单个图片大小请勿超过5M, 最多上传3张图片
                                </span>
                                </template>
                            </el-upload>
                        </el-form-item>
                        <el-form-item label="" prop="emial">
                            <el-input v-model="Mform.emial" placeholder="邮箱账号"/>
                        </el-form-item>
                        <el-form-item label="" prop="phone">
                            <el-input v-model="Mform.phone" placeholder="手机号码" />
                        </el-form-item>
                        <el-form-item label="">
                            <el-button style="width: 100%;" type="primary" @click="submit('Mform')">提交</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </el-tab-pane>
        </el-tabs>
        
    </el-dialog>
</template>
<script>
import { userRegister, museumList } from '@/api/user.js'
import { isvalidPwd } from '@/utils/validate.js'
import { encrypt } from '@/utils/rsaEncrypt'
export default{
    data(){
        const validatePass = (rule, value, callback)=>{
            if (value === '') {
                callback(new Error('请再次输入密码'))
            } else if (value !== (this.activeName=='0'?this.form.password:this.Mform.password)) {
                callback(new Error("两次密码输入不一致!"))
            } else {
                callback()
            }
        }
        const validatePhone = (rule, value, callback)=>{
            var re = /^1[3,4,5,6,7,8,9][0-9]{9}$/;
            var result = re.test(value); 
            if(!result) {
                callback(new Error("手机号码格式不正确!"))
            }else{
                callback() 
            }
        }
        const validPwd = (rule, value, callback) => {
            if (value) {
                if(isvalidPwd(value)){
                    callback()
                }else{
                    callback(new Error('密码包含字母、数字、特殊字符且长度至少八位'))
                }
            } else {
                callback()
            }
        }
        return{
            dialogVisible:false,
            activeName:'0',
            loading:false,
            form:{
                type:1,
                username:null,
                password:null,
                pwd2:null,
                emial:null,
                phone:null,
            },
            rules:{
                username:[
                    { required: true, message: '请输入用户名', trigger: 'blur' }
                ],
                password:[
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    { validator: validPwd, trigger: 'blur' }
                ],
                pwd2:[
                    { required: true, message: '请再次输入密码', trigger: 'blur' },
                    { validator: validatePass, trigger: 'blur' }
                ],
                emial:[
                    { required: true, message: '请输入邮箱', trigger: 'blur' },
                    { type: 'email', message: '请输入正确的邮箱地址',trigger: 'blur' }
                ],
                phone:[
                    { required: true, message: '请输入电话', trigger: 'blur' },
                    { validator: validatePhone, trigger: 'blur' }
                ],
            },
            imageList:[],
            Mform:{
                type:2,
                username:null,
                password:null,
                pwd2:null,
                fileUrl:[],
                name:null,
                site:null,
                emial:null,
                phone:null,
            },
            Mrules:{
                username:[
                    { required: true, message: '请输入用户名', trigger: 'blur' }
                ],
                password:[
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    { validator: validPwd, trigger: 'blur' }
                ],
                pwd2:[
                    { required: true, message: '请再次输入密码', trigger: 'blur' },
                    { validator: validatePass, trigger: 'blur' }
                ],
                emial:[
                    { required: true, message: '请输入邮箱', trigger: 'blur' },
                    { type: 'email', message: '请输入正确的邮箱地址',trigger: 'blur' }
                ],
                phone:[
                    { required: true, message: '请输入电话', trigger: 'blur' },
                    { validator: validatePhone, trigger: 'blur' }
                ],
                name:[{required: true, message: '请输入姓名', trigger: 'blur' }],
                fileUrl:[{required: true, message: '请上传资质', trigger: 'blur' }],
                site:[{required: true, message: '请选择博物馆', trigger: 'blur' }],
            },
            museumOption:[]
        }
    },
    methods:{
        tabChange(e){
            this.activeName = e
        },
        remoteMethod(query){
            if(query){
                this.loading = true
                museumList({'site':query}).then(r=>{
                    this.loading = false
                    this.museumOption = r.data
                })
            }else{
                this.museumOption = []
            }
        },
        open(){
            this.dialogVisible = true
            this.$nextTick(()=>{
                this.$refs.form.resetFields()
                this.$refs.Mform.resetFields()
                this.imageList = []
            })
        },
        HttpRequest(param){
            this.Mform.fileUrl.push(param.file)
        },
        HandleRemove(file, fileList) {
            this.imageList = fileList
            this.Mform.fileUrl = fileList.filter(i => {
                if(i.size){
                    return i
                }
            })
        },
        BeforeUpload(file) {
            const isLt2M = file.size / 1024 / 1024 < 3;
            if (!isLt2M) {
            this.$message.warning('图片大小不能超过 3MB!');
            }
            return isLt2M;
        },
        submit(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let fromData = null
                    if(this.activeName == '0'){
                        let data = JSON.parse(JSON.stringify(this.form))
                        let Datas = {
                            username:data.username,
                            password:encrypt(this.form.password),
                            type:data.type,
                            emial:data.emial,
                            phone:data.phone
                        }
                        fromData = new FormData()
                        for(let key in Datas){
                            fromData.append(key,Datas[key])
                        }
                    }else{
                        let data = JSON.parse(JSON.stringify(this.Mform))
                        fromData = new FormData()
                        fromData.append('username',data.username)
                        fromData.append('type',data.type)
                        fromData.append('emial',data.emial)
                        fromData.append('password',encrypt(this.Mform.password))
                        fromData.append('phone',data.phone)
                        fromData.append('site',data.site)
                        fromData.append('name',data.name)
                        this.Mform.fileUrl.forEach(el => {
                            fromData.append('fileUrl',el)
                        });
                    }
                    userRegister(fromData).then(r=>{
                        if(r.code == 200){
                            this.dialogVisible = false
                            this.$message.success(r.data)
                        }else{
                            this.dialogVisible = true
                            this.$message.warning(r.message)  
                        }
                    })
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.formBox{
    width: 100%;
    display: flex;
    justify-content: center;
    
}
.line{
    width: 300px;
    margin: 0 auto;
}

</style>